(function () {
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    const programTime = new Date(2023, 10, 15, 0, 0).getTime(),
        programTimer = setInterval(() => {

            const now = new Date().getTime(),
                distance = programTime - now;

            document.getElementById("days").innerText = Math.floor(distance / (day)).toString(),
                document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)).toString(),
                document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)).toString(),
                document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second).toString();

            //do something later when date is reached
            if (distance < 0) {
                document.getElementById("headline").innerText = "It's to go!";
                document.getElementById("countdown").style.display = "none";
                document.getElementById("content").style.display = "block";
                clearInterval(programTimer);
            }
            //seconds
        }, 0),
        registerTime = new Date(2023, 9, 1).getTime(),
        registerTimer = setInterval(() => {
            const now = new Date().getTime(),
                distance = registerTime - now;

            document.getElementById("days1").innerText = Math.floor(distance / (day)).toString(),
                document.getElementById("hours1").innerText = Math.floor((distance % (day)) / (hour)).toString(),
                document.getElementById("minutes1").innerText = Math.floor((distance % (hour)) / (minute)).toString(),
                document.getElementById("seconds1").innerText = Math.floor((distance % (minute)) / second).toString();

            //do something later when date is reached
            if (distance < 0) {
                document.getElementById("headline1").innerText = "It's to go!";
                document.getElementById("countdown1").style.display = "none";
                document.getElementById("content1").style.display = "block";
                clearInterval(registerTimer);
            }
            //seconds
        }, 0);
}());